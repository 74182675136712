import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="40px" height="43px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">

      <g id="SVGRepo_bgCarrier" stroke-width="0"/>

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

      <g id="SVGRepo_iconCarrier"> <title>hourglass-null</title> <desc>Created with Sketch.</desc> <g id="hourglass-null" stroke-width="2.688" fill="none" fill-rule="evenodd" stroke-linejoin="round"> <rect width="48" height="48" fill="white" fill-opacity="0.01"/> <g id="编组" transform="translate(7.000000, 3.500000)" stroke="#000000" stroke-width="2.688"> <path d="M0,0.5 L34,0.5" id="路径-3" stroke-linecap="round"> </path> <path d="M0,40.5 L34,40.5" id="路径-3" stroke-linecap="round"> </path> <path d="M30,20.4999965 C27.3333333,33.8388874 23,40.5055541 17,40.4999965 C11,40.494439 6.66666667,33.8277723 4,20.4999965 L30,20.4999965 Z" id="路径-10" fill="#fb00ff" fill-rule="nonzero" transform="translate(17.000000, 30.499998) rotate(180.000000) translate(-17.000000, -30.499998) "> </path> <path d="M30,0.5 C27.3333333,13.8388909 23,20.5055575 17,20.5 C11,20.4944425 6.66666667,13.8277758 4,0.5 L30,0.5 Z" id="路径-10" fill="#fb00ff" fill-rule="nonzero"> </path> </g> </g> </g>

    </svg>
  );
}

export default SvgComponent;

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg height="43px" width="43px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000000">

    <g id="SVGRepo_bgCarrier" stroke-width="0"/>

    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

    <g id="SVGRepo_iconCarrier"> <polygon fill="yellow" points="427.917,104.772 427.917,101.111 13.562,101.111 13.562,104.772 220.74,271.721 "/> <g> <path fill="#000000;" d="M512,340.353c0-41.746-30.586-76.468-70.521-82.974v-64.341c0-7.49-6.073-13.562-13.562-13.562 c-7.489,0-13.562,6.072-13.562,13.562v64.341c-33.281,5.422-60.054,30.441-68.057,62.792l-39.763-34.158 c-5.68-4.881-14.241-4.231-19.125,1.45c-4.881,5.681-4.23,14.244,1.451,19.125l56.26,48.327 c2.841,16.195,10.319,30.811,21.029,42.413H46.984l105.633-90.739c5.681-4.881,6.332-13.442,1.451-19.125 c-4.882-5.681-13.441-6.332-19.125-1.45L27.124,378.633V133.118L212.23,282.28c2.483,2.002,5.497,3.001,8.509,3.001 s6.025-1.001,8.509-3.001l207.18-166.947c3.194-2.574,5.053-6.457,5.053-10.561v-3.663c0-7.49-6.073-13.562-13.562-13.562H13.562 C6.073,87.548,0,93.619,0,101.109v3.663v303.387v2.731c0,7.49,6.073,13.562,13.562,13.562h414.355c0.231,0,0.457-0.023,0.685-0.035 C474.649,424.047,512,386.486,512,340.353z M394.018,114.672L220.739,254.303L47.461,114.672H394.018z M427.917,397.313 c-31.408,0-56.96-25.552-56.96-56.96s25.552-56.96,56.96-56.96s56.96,25.552,56.96,56.96S459.325,397.313,427.917,397.313z"/> <path fill="#000000;" d="M427.917,345.669c-7.489,0-13.562-6.072-13.562-13.562v-25.088c0-7.49,6.073-13.562,13.562-13.562 c7.489,0,13.562,6.072,13.562,13.562v25.088C441.479,339.598,435.407,345.669,427.917,345.669z"/> <path fill="#000000;" d="M427.917,382.627c-7.489,0-13.562-6.072-13.562-13.562v-0.677c0-7.49,6.073-13.562,13.562-13.562 c7.489,0,13.562,6.072,13.562,13.562v0.677C441.479,376.555,435.407,382.627,427.917,382.627z"/> </g> </g>

    </svg>
    );
}

export default SvgComponent;

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { visuallyHidden } from '@mui/utils';
import styled from "styled-components";

import { ColorPalet1, ColorPalet2, ColorPalet3, ColorPalet4 } from '../../assets/colors/colorpalet';
import { Button } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import avatar1 from '../../assets/img/avatar1.jpg'
import avatar2 from '../../assets/img/avatar2.jpg'
import avatar3 from '../../assets/img/avatar3.jpg'
import avatar4 from '../../assets/img/avatar4.jpg'
import avatar5 from '../../assets/img/avatar5.jpg'
import avatar6 from '../../assets/img/avatar6.jpg'
import avatar7 from '../../assets/img/avatar7.jpg'
import avatar8 from '../../assets/img/avatar8.jpg'
import avatar9 from '../../assets/img/avatar9.jpg'

function createData(avatar,name, age, category, profession, extra) {
  return {
    avatar,
    name,
    age,
    category,
    profession,
    extra,
  };
}



const rows = [
  createData(avatar1,'Tim', 27, "Alleenstaand", "Zorg", 4.3),
  createData(avatar2,'Bram & Casper', 25, "Vrienden", "Consultant", 4.9),
  createData(avatar3,'Dirk & Valerie', 35, "Partner", "Zorg", 6.0),
  createData(avatar4,'Titus & Oscar', 29, "Partner", "Onderwijs", 4.0),
  createData(avatar5,'Sam', 21, "Alleenstaand", "Onderwijs", 3.9),
  createData(avatar6,'Lotte', 24, "Alleenstaand", "Zorg", 6.5),
  createData(avatar7,'Olivier & Anne', 27, "Partner", "Marketing", 4.3),
  createData(avatar8,'Robyn & Jule', 33, "Vrienden", "Consultant", 0.0),
  createData(avatar9,'Naif & Sumaira', 38, "Partner", "Retail", 7.0),
  createData(avatar1,'Ina', 36, "Alleenstaand", "Onderwijs", 0.0),
  createData(avatar2,'Jonas & Michelle', 29, "Partner", "Retail", 2.0),
  createData(avatar3,'Farah', 20, "Alleenstaand", "Transport", 37.0),
  createData(avatar4,'Yannic & Laurie', 27, "Partner", "Onderwijs", 4.0),
];

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'select all',
  },
  {
    id: 'picture',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'age',
    numeric: true,
    disablePadding: false,
    label: 'Leeftijd',
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Categorie',
  },
  {
    id: 'profession',
    numeric: true,
    disablePadding: false,
    label: 'Beroep',
  },
  {
    id: 'info',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ProfielUitkiezenTable(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h5"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <StyledTypography
          sx={{ flex: '1 1 100%' }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Selecteer huurders
        </StyledTypography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

ProfielUitkiezenTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Wrapper>
        <Box sx={{ width: '100%'}}>
        <Paper sx={{ width: '100%', mb: 2 }}>
            <ProfielUitkiezenTable numSelected={selected.length} />
            <StyledTableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
                stickyHeader
            >
                <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                />
                <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                            <Checkbox
                            color="blue"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </TableCell>
                        <TableCell align="left"><StyledAvatar src={row.avatar}/></TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{color: 'red'}}
                        >
                            {row.name}
                        </TableCell>
                        <TableCell align="right">{row.age}</TableCell>
                        <TableCell align="right">{row.category}</TableCell>
                        <TableCell align="right">{row.profession}</TableCell>
                        <StyledTableCell align="right">bekijk profiel</StyledTableCell>
                        </TableRow>
                    );
                    })}
                {emptyRows > 0 && (
                    <TableRow
                    style={{
                        height: (53) * emptyRows,
                    }}
                    >
                    <TableCell colSpan={6} />
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </StyledTableContainer>
            <StyledButton variant="contained" size="large" color="primary">
                Verstuur Uitnoding
            </StyledButton>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        </Box>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 120px;
`;

const StyledTableContainer = styled(TableContainer)`
  border-radius: 12px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-left: 30px !important
`;

const StyledTypography = styled(Typography)`
  font-weight: 800;
  color: #555;
`;


const StyledAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const StyledTableCell = styled(TableCell)`
  color: ${ColorPalet1} !important;
`;
